<template>
  <div id="app">
    <router-view/>
    <!-- loading -->
    <div class="calculator__loader" v-show="showLoad">
      <div class="calculator__loader_inner">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'App',
  data(){
	return{
		showLoad:'',
    isEN:'en',
	}
  },
  created(){
    if(localStorage.getItem('lang')) this.isEN = localStorage.getItem('lang')
  },
	methods: {
    // code判断
    returnCode (data,callback) {
      let code = data.code;
      console.log(code)
      //callback();
      if(code == 200){
        callback();
        this.showLoad = false;
      }else if(code==1001 || code==1002 || code==1003 || code==1004){
        this.showLoad = false;
        this.$router.push('/login');
      }else{
        this.showLoad = false;
        this.$utils.alertText(data.msg);
      }
    },
		loadModal(isShow){
			let that = this;
			that.showLoad = isShow;
		}
	}
}
</script>
<style>
  @import "./style/ui-range.css";
  @import "./style/styles.min.css";
	@import "./style/style.css";
	.modal{position:fixed;top:0;left:0;width:100%;height:100%;z-index:999;}
	.modal-bg{width:100%;height:100%;background:rgba(0,0,0,.5);}
	.modal-content{position:absolute;top:50%;left:50%;transform:translate(0,-50%);}
	.loadCont p{color:#fff;margin:10px 0 0; font-size:0.32rem;}
</style>
