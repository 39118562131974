// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue'
import Element from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import locale from 'element-ui/lib/locale/lang/en' // lang i18n
import App from './App'
import router from './router'
import $ from 'jquery'
import api from './api/index.js'
import utils from './utils/index.js'
import axios from 'axios'
import i18n from './i18n/index.js'
import Clipboard from 'v-clipboard'
import getPageTitle from './utils/get-page-title.js'
import './registerServiceWorker'
window.jQuery = $
window.$ = $
Vue.config.productionTip = false
Vue.prototype.$api = api
Vue.prototype.$utils = utils
Vue.prototype.$axios=axios
Vue.prototype.$baseUrl = process.env.BASE_URL
Vue.use(Clipboard)
Vue.use(Element, { locale })
Vue.use(Element, { size: 'small', zIndex: 3000 });

router.beforeEach((to, from, next) => {
	// to来自  from  去哪
	if(to.meta.title) {
        document.title = getPageTitle(to.meta.title)
	}
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;

    // 兼容IE
    window.scrollTo(0, 0);
	next()
})

/* eslint-disable no-new */
new Vue({
    el: '#app',
    router,
    i18n,
    render: h => h(App)
})