module.exports = {
    route:{
        CashAvatar: 'CashAvatar',
        Home: 'CashAvatar',
        howToBorrow: 'ණයක් ගන්න',
        howToRepay: 'ණය ආපසු ගෙවීම',
        faq: 'නිති අසන ප්‍රශ්න',
        about: 'අප පිළිබඳව',
        contact: 'අප අමතන්න',
        facebook: 'අපගේ සමාජ ජාල',
        privacyPolicy: 'Privacy Policy',
        term: 'Term & Conditions',
        RepaymentHistory:'Repayment History',
        BankCard: 'Bank Card',
        LoanHistory: 'Loan History',
        CustomerService: 'Customer Service',
        ContactUs: 'අප අමතන්න',
        Password: 'Password',
        LogOut: 'Log Out',
        userCenter: 'User Center',
        notification: 'Notification',
        photoRollback: 'Photo Rollback',
        bankRollback: 'Bank Rollback',
        changePassword: 'Change Password',
        UploadVoucher: 'Upload Repayment Voucher',
        repayment: 'Repayment',
        Result: 'Result',
        Applyloan: 'Apply for loan',
        IdentityInformation: 'Identity Information',
        BankInformation: 'Bank Information',
        EmergencyContacts: 'Emergency Contacts',
        PersonalInformation: 'Personal Information',
        UserCenter: 'User Center',
        pwdLogin: 'Password Login',
        smsLogin: 'Verification code Login',
        register: 'Register',
        login: 'Login'
    },
    table:{
        btnLogin: "ඇතුල් වන්න",
        week: 'සතියේ දින හත',
        everyDay: 'සියලු දින: පෙ.ව 8:00 - ප.ව 8:00',
        continue: 'ඉදිරියට යන්න',
        login: 'පිවිසුම්',
        pin: 'PIN',
        cancel:'අවලංගු කරන්න',
        confirm: 'තහවුරු කරන්න',
        tips: 'ඉඟ',
        validCode: 'සත්‍යාපන කේතය',
        getCode: 'OTP',
        agree: 'මම කියවා  එකඟ වීමි',
        forgotPassword: 'මුරපදය අමතක වුණා ද?',
        clickHere: 'මෙතන ක්ලික් කරන්න',
        BankName: 'බැංකුවේ නම',
        Branch: 'ශාඛාව',
        CardHolderName: "කාඩ්පත් ලාභියාගේ නම",
        BankAccount: 'බැංකු ගිණුම',
        add: 'එකතු කරන්න',
        Details: 'විස්තර',
        submit: 'ඉදිරිපත් කරන්න',
        next: 'ඊළඟ',
        copy:'පිටපත් කරන්න',
        passwordConfirmation: 'මුරපද තහවුරු කිරීම',
        upload: 'උඩුගත කරන්න',
        more: 'තව >>',
        vipBenefits: 'VIP User Benefits',
        TakeLoan:'ණය මුදලක් ගන්න',
        step: 'Step',
        phone: '0729171546',
        email:'business@cashavatar.online',
        facebook:'https://www.facebook.com/CashAvatar.live',
        desktop:'CashAvatar අඩවි කෙටිමඟ ඔබේ ඩෙස්ක්ටොප් එකට එක් කරන්න'
    },
    tips:{
        phoneTips: 'දුරකථන අංකය හිස් විය නොහැක',
        passwordTips:'මුරපද අංක 6ක් ඇතුලත් කරන්න',
        loginOut:'ඔබට පිටවීමට අවශ්‍ය බව විශ්වාසද??',
        exit:'සාර්ථකව පිටවීම',
        codeTips: 'සත්‍යාපන කේතය ඇතුලත් කරන්න',
        PleaseEnter: 'කරුණාකර ඇතුලත් කරන්න',
        PleaseSelect: 'කරුණාකරලා  තෝරාගන්න',
        delectTips: 'ඔබට එය මැකීමට විශ්වාසද?',
        voucheruploadTips: 'කරුණාකර ආපසු ගෙවීමේ වවුචරය upload කරන්න',
        copyTips: 'සාර්ථකව පිටපත් කර ඇත',
        agreeTips:'කරුණාකර පුද්ගලිකත්ව ප්‍රතිපත්තිය, නියම සහ කොන්දේසි සහ ණය ගිවිසුම පිළිගන්න',
        disableTip:'නිෂ්පාදන වෙනසක් ඉල්ලා සිටීමට ඔබේ මට්ටම ඉතා අඩුය',
    },
    step: {
        pageTitle1: 'පුද්ගලික තොරතුර',
        pageTitle2: 'හදිසි සම්බන්ධතා',
        pageTitle3: 'බැංකු තොරතුරු',
        pageTitle4: 'අනන්යතා තොරතුරු',
        firstName: 'පළමු නම',
        firstNameError: 'කරුණාකර ඇතුලත් කරන්න පළමු නම',
        lastName: 'අවසාන නම',
        lastNameError: 'කරුණාකර ඇතුලත් කරන්න අවසාන නම',
        gender: 'ස්ත්‍රී පුරුෂ භාවය',
        genderError: 'කරුණාකරලා  තෝරාගන්න ස්ත්‍රී පුරුෂ භාවය',
        nic: 'NIC',
        nicError: 'කරුණාකර ඇතුලත් කරන්න NIC',
        email: 'ඊමේල්',
        emailError: 'කරුණාකර ඇතුලත් කරන්න ඊමේල්',
        birthday: 'උපන් දිනය',
        birthdayError: 'කරුණාකරලා  තෝරාගන්න උපන් දිනය',
        eduction: 'අධ්යාපනය',
        eductionError: 'කරුණාකරලා  තෝරාගන්න අධ්යාපනය',
        address: 'සම්පූර්ණ ලිපිනය',
        addressError: 'කරුණාකර ඇතුලත් කරන්න සම්පූර්ණ ලිපිනය',
        safeTips: 'අපි ඔබේ තොරතුරු සුරක්ෂිතව තබා ගන්නෙමු',
        companyName: 'සමාගමේ නම',
        companyNameError: 'කරුණාකර ඇතුලත් කරන්න සමාගමේ නම',
        monthlyIncome: 'මාසික ආදායම',
        monthlyIncomeError: 'කරුණාකරලා  තෝරාගන්න මාසික ආදායම',
        job: 'තනතුර',
        jobError: 'කරුණාකරලා  තෝරාගන්න තනතුර',
        companyInformation: 'සමාගමේ තොරතුරු',
        name: 'නම',
        nameError: 'කරුණාකර ඇතුලත් කරන්න නම',
        phone: 'දුරකථන',
        phoneError: 'කරුණාකර ඇතුලත් කරන්න දුරකථන',
        relationship: 'සම්බන්ධතාවය',
        relationshipError: 'කරුණාකරලා  තෝරාගන්න සම්බන්ධතාවය',
        emergencyContacts: 'හදිසි සම්බන්ධතා',
        titleTips: 'හදිසි සම්බන්ධතා එකම පුද්ගලයා විය නොහැක; ඒවා සමාන නම්, යෙදුම අසාර්ථක වනු ඇත.',
        bankName: 'බැංකුවේ නම',
        bankNameError: 'කරුණාකරලා  තෝරාගන්න ඔබගේ ගිණුම විවෘත කිරීමේ බැංකුව',
        branch: 'ශාඛාව',
        branchError: 'කරුණාකරලා  තෝරාගන්න ශාඛාව',
        CardHolderName: "කාඩ්පත් ලාභියාගේ නම",
        CardHolderNameError: "කරුණාකර ඇතුලත් කරන්න කාඩ්පත් ලාභියාගේ නම",
        bankAccount: 'බැංකු ගිණුම',
        bankAccountError: 'කරුණාකර ඇතුලත් කරන්න බැංකු ගිණුම',
        nicFront:'ජාතික හැඳුනුම්පතේ ඉදිරිපස ඡායාරූපය',
        nicBack:'ජාතික හැඳුනුම්පත අතේ තබාගත් ඡායාරූපය',
        selfiePhoto:'සෙල්ෆි ඡායාරූපය',
        revenuePhotos:'ආදායම් සනාථ කරන ඡායාරූපය',
        nicFrontError:'කරුණාකර ජාතික හැඳුනුම්පතේ ඉදිරිපස ඡායාරූපය උඩුගත කරන්න',
        nicBackError:'කරුණාකර NIC Back හි ඡායාරූපය උඩුගත කරන්න',
        selfiePhotoError:'කරුණාකර ජාතික හැඳුනුම්පත අතේ තබාගෙන ඡායාරූපය උඩුගත කරන්න',
        incomePhotoError:'කරුණාකර ආදායම් සහතිකයේ ඡායාරූපය උඩුගත කරන්න',
        photoTips:'කරුණාකර පැහැදිලි පින්තූර ගැනීමට උත්සාහ කරන්න, එසේ නොමැතිනම් එය හඳුනාගැනීමේ ප්‍රතිඵලවලට බලපානු ඇත.',
        bankTips:'Sampath Bank PLC භාවිතා කිරීම රෙකමදාරු කරනු ලැබේ, ඔබට ඉක්මනින් ණය අරමුදල් ලැබෙනු ඇත, වෙනත් බැංකු භාවිතා කිරීමේදී පැය 48 ක ප්‍රමාදයක් ඇත.',
        workLicense: 'වැඩ බලපත්රය',
        workLicenseError: 'වැඩ බලපත්‍රය හෝ ඇඳුම් සහිත ඡායාරූපය',
        cameraTips:'පින්තූර ගැනීමට මෙය වෙනස් කිරීමට අපහසුය. උදව් සඳහා කරුණාකර ක්ලික් කරන්න.',
    },
    loan:{
        loanTitle:'කලින් අයදුම් කරන්න, ඉක්මනින් ණය ලබා ගන්න!',
        LoanAmount:'ණය මුදල',
        loan2:'ණය කාල සීමාව සහ විස්තර',
        loan3:'වැට් ඇතුලත්',
        loan4:'අපි ඔබේ ණය තත්ත්වය ඇගයීමට ලක් කරමින් සිටිමු, කරුණාකර ඉවසන්න, අපි ඉක්මනින් කරන්නෙමු. කරුණාකර ජාලය විවෘතව තබා ගන්න',
        loanPeriod:'ණය කාලය',
        days:'දින',
        amountReceived:'ලැබුණු මුදල',
        paymentAmount:'ගෙවීම් මුදල',
        paymentAccount: 'Payment Account',
        interest:'පොළිය',
        origniationFee:'සම්භවය ගාස්තුව',
        bankAccount:'බැංකු ගිණුම',
        toBorrowMoney:'ණය සඳහා  අයදුම් කරන්න',
        goHome:'ගෙදර යන්න',
        applyTips:'ඔබගේ අයදුම්පත පැය 2ක් ඇතුළත සමාලෝචනය කෙරේ',
        dueRepayment:'නැවත ගෙවීමේ මුදල',
        repaymentDate:'නැවත ගෙවීමේ දිනය',
        duration:'කාලසීමාව',
        orderNumber:'අැනවුම් අංකය',
        repayBank:'බැංකුවට ආපසු ගෙවන්න',
        submitButton:'ආපසු ගෙවීමේ වවුචරය ඉදිරිපත් කරන්න',
        offlineTitle:'Offline ආපසු ගෙවීමේ ගිණුම',
        label2:'ශාඛාව',
        label3:'බැංකුවේ නම',
        remark:"අදහස් දැක්වීම",
        remarkContent:'සටහන් ඇණවුම් අංකයක් නොමැති නම්, කරුණාකර ඔබගේ මාරු කිරීමේ සටහන එක් කරන්න, එසේ නොමැතිනම් අපට බිල්පත සත්‍යාපනය කිරීමට නොහැකි වනු ඇත.',
        cameraTips:'ආපසු ගෙවීමේ පත්‍රිකාව උඩුගත කළ නොහැකි නම්. උදව් සඳහා කරුණාකර ක්ලික් කරන්න',
        Voucher: 'ආපසු ගෙවීමේ වවුචරය උඩුගත කරන්න'
    },
    index:{
        title:'ණයක් ලබා ගන්න',
        title2:'මාර්ගගත',
        title3:'මිනිත්තු 10 කින්!',
        title4:'ගෙවිය යුතු සම්පූර්ණ මුදල',
        title5:'CashAvatar හි වාසි',
        title6:'වේගවත් මුදල්',
        title7:'අපි ඔබගේ ගිණුම වෙත ක්ෂණිකව මුදල් මාරු කර සිටීමට කටයුතු කරන්නෙමු',
        title8:'සරල ක්‍රියාවලිය',
        title9:'වලංගු ජාතික හැඳුනුම්පත් අංකයක් සමඟ පහසු සහ කෙටි ලියාපදිංචිය',
        title10:'පහසු ආපසු ගෙවීමේ ක්‍රම',
        title11:'ඔබගේ බැංකු ශාඛාව හරහා හෝ අන්තර්ජාල බැංකු සේවාව හරහා පහසු ආපසු ගෙවීමේ ක්‍රම',
        title12:'අපගේ සේවාවන් භාවිතා කළ හැකිවන්නේ කවුරුන් හටද?',
        title13:'වයස',
        title14:'වයස අවුරුදු 20ත් 60ත් අතර',
        title15:'ස්ථානය',
        title16:'ශ්‍රී ලංකාවේ පදිංචිය',
        title17:'රැකියාව',
        title18:'ආදායමක් තිබේද යන්න',
        title19:'ණය පහසුකමක් සලසා ගැනීම සදහා ඇති පහසුම සහ වේගවත්ම පියවරයන්',
        title20:'අවශ්‍ය ණය මුදල් ප්‍රමාණයක් තෝරාගන්න',
        title21:'පුද්ගලික දත්ත සමඟ පෝරමය සම්පූර්ණ කරන්න',
        title22:'සමාලෝචන ප්‍රතිඵලය ලැබෙන තෙක් රැඳී සිටින්න',
        title23:'ගිවිසුම අත්සන් කර මුදල් ලබා ගන්න',
    },
    HowToBorrow:{
        title:'How to get a loan in CashAvatar?',
        title2:'Here is a step-by-step guide to help you through the process',
        title3:'Determine your loan amount',
        title4:'As a new customer, simply select the desired loan amount',
        title5:'Fill out the form',
        title6:"All you need is your ID and phone number. It’s quick and easy – just a few minutes to complete",
        title7:'Await an application decision',
        title8:"We’ll notify you immediately or reach out if we need more information",
        title9:'Sign the agreement',
        title10:"Once you confirm your agreement to obtain the loan online, you’ll receive a confirmation code via SMS. And with our streamlined process, your loan will be transferred in real-time, making it as simple as can be",
    },
    HowToRepay:{
        title:'How to repay',
        title2:"At CashAvatar, we recognize that each customer has unique needs and preferences. That’s why our repayment options are designed to give you the flexibility to choose how you want to pay back your loan",
        title3:"To maximize convenience, you can choose from a variety of payment methods:",
        title4:'Money transfer within 1-3 days',
        title5:'Sampath Bank transfer',
        title6:"You can easily pay back via Sampath Bank branches choosing one of the closest to your location.",
        title7:'Please fill in the Sampath Bank deposit slip with the following information and hand it over to the teller:',
        title8:'Account holder Name',
        title9:'Megafin Lanka (pvt) Ltd',
        title10:'Account Number',
        title11:'0214 1000 2700',
        title12:'Branch',
        title13:'Orugodawatta',
        title14:"In order to make a payment through the bank’s CDM Sampath bank, you must:",
        title15:'Please select the language;',
        title16:'Select',
        title17:'CASH DEPOSIT',
        title18:'Enter your 10-digit mobile number;',
        title19:'Enter your',
        title20:'NIC number',
        title21: 'Enter Sampath Bank account number as',
        title22: 'Make sure the account name is automatically displayed as',
        title23: 'Order NO',
        title24: 'again as',
        title25: 'REFERENCE NO',
        title26: "Place cash notes into the deposit tray (Only Rs. 100 and higher cash notes can be used);",
        title27: 'The amount you deposited will be displayed on the screen. Press “Proceed” if correct;',
        title28: 'Take the payment receipt from the machine and keep it.',
        title29: 'NOTE:',
        title30: "Whenever you make a repayment, send us a clear slip photo or screenshot of your Payment Slip on to our phone numbers 94712015180 via WhatsApp, Viber, imo or our Facebook Messenger with your Order NO.",
    },
    about:{
        title:'About us',
        title2:"CashAvatar understand that getting the money you need for your plans can be a hassle, which is why we provide fast, easy, and flexible financing solutions through our online platform. Whether you’re looking to make small changes or take on big projects, our service is designed to help you focus on your goals without worrying about tedious or complicated procedures. Aspiring to become the leading provider of online money services in Sri Lanka, we pride ourselves on understanding the needs and aspirations of our customers, and we’re committed to providing you with the best possible experience. CashAvatar is here to help you get the funding you need, quickly and securely",
        title3:'Why CashAvatar?',
        title4:'Clear terms and loan amounts',
        title5:'Simplest process and easy repayment',
        title6:'Lowest fees in Sri Lanka',
        title7:'96% approved application',
        title8:'Customer loyalty program',
        title9:'The service is fully automated',
    },
    contact:{
        title:'Contact us',
        title2:'Support service',
        title3:'Mail address',
        title4:'Schedule',
        title5:'Facebook page',
        title6:'Address',
        title7:'No. 47 Alexandra Place, Colombo 07',
    },
    faq:{
        title:'FAQ',
        title2:'What is CashAvatar?',
        title3:"CashAvatar is an online lending platform for those facing unexpected expenses and needing a loan quickly. The loan amount can range from Rs 5,000 to Rs 80,000.",
        title4:'Credit information',
        title5:"Here’s what you’ll need regarding your credit information:",
        title6:'Age and Employment: to be eligible for a loan, you must be between the ages of 20 to 60 and currently employed or have a steady income.',
        title7:'Personal and Bank Information: make sure to accurately input your personal and bank account information.',
        title8:"Telephone and work information: the telephone number you use for the application must be registered in your name. Additionally, you’ll need to provide a landline number for your place of work.",
        title9:"Identification verification: to verify your identity, you’ll need to include a photo of your National Identity Card.",
        title10:"By providing all of this information, you’ll increase your chances of being approved for a loan. Remember to double-check your information to ensure accuracy and speed up the process.",
        title11:'How much is the service and consulting fees?',
        title12:"You can easily check the full repayment amount before you apply through our loan calculation form. As long as you make timely payments, there shouldn’t be any additional fees. Be sure to check the repayment amount before accepting the loan and only take it if you’re confident you can pay it back on time. Our priority is to provide you with flexible and affordable loan options.",
        title13:"How do I know if the loan is approved?",
        title14:"Curious about the status of your loan application? Rest easy knowing you can track it through your private cabinet on our website. Plus, once your loan is approved, we’ll send you an exciting notification via SMS! Stay in the loop and let CashAvatar help you reach your financial goals.",
        title15:'How long will it take to process my loan application?',
        title16:"As soon as you submit your loan application, we’ll keep you in the loop every step of the way. We’ll notify you right away about your application status or contact you if we need any additional information. And don’t worry about lingering in limbo – our application processing time typically ranges from just 10 minutes to one business day during our working hours. We’ll work quickly to get you the answers you need so you can proceed with confidence.",
        title17:'I did not receive a loan approval notification from CashAvatar. What can I do?',
        title18:"If you haven’t received a loan approval notification from CashAvatar, don’t worry! We’re here to assist you. Our friendly team can help you with any queries or concerns you may have. Reach out to us through any of our communication channels:",
        title19:"Facebook",
        title20:"Email",
        title21:"Phone",
        title22:"About cash receipts",
        title23:"When it comes to cash receipts and loan applications, we want to ensure that you have access to your funds as soon as possible. That’s why we strive to have your money credited to your bank account within just two hours of approving your loan application. You can trust us to deliver fast and reliable service to help you get the financial support you need.",
        title24:"About making payments to us",
        title25:"As part of your loan contract, we have specified the date and amount for repayment. To avoid any penalties, it’s important that you make your payment on or before the due date. Don’t hesitate to contact us if you have any questions or concerns about your payments.",
        title26:"Can I repay the loan earlier?",
        title27:"Absolutely! We understand that sometimes unexpected financial situations arise, and you may want to pay off your loan earlier than originally planned. You are free to make early payments at any time with no penalty fees. Just be sure to make the payments before the due date to avoid any late fees. We want to make your loan experience as flexible and easy as possible, so don’t hesitate to reach out to us with any questions or concerns.",
    }
}
