// src/plugins/VueI18n/index.js：
import Vue from 'vue';
import VueI18n from 'vue-i18n';
import enLocale from './en'
import burLocale from './bur'
Vue.use(VueI18n);

// 定义各语言的配置
const messages = {
  en: {
    ...enLocale
  },
  lk: {
    ...burLocale
  },
};

// 通过选项创建 VueI18n 实例
const i18n = new VueI18n({
    locale: (function () {
    if (localStorage.getItem('lang')) {
      return localStorage.getItem('lang')
    }
    return 'en'
  }()), // 设置默认地区
    messages, // 设置配置信息
});

export default i18n
