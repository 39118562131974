module.exports = {
  route:{
    CashAvatar: 'CashAvatar',
    Home: 'CashAvatar',
    howToBorrow: 'How to borrow',
    howToRepay: 'How to repay',
    faq: 'FAQ',
    about: 'About us',
    contact: 'Contact us',
    facebook: 'Our social networks',
    privacyPolicy: 'Privacy Policy',
    term: 'Term & Conditions',
    RepaymentHistory:'Repayment History',
    BankCard: 'Bank Card',
    LoanHistory: 'Loan History',
    CustomerService: 'Customer Service',
    ContactUs: 'Contact Us',
    Password: 'Password',
    LogOut: 'Log Out',
    userCenter: 'User Center',
    notification: 'Notification',
    photoRollback: 'Photo Rollback',
    bankRollback: 'Bank Rollback',
    changePassword: 'Change Password',
    UploadVoucher: 'Upload Repayment Voucher',
    repayment: 'Repayment',
    Result: 'Result',
    Applyloan: 'Apply for loan',
    IdentityInformation: 'Identity Information',
    BankInformation: 'Bank Information',
    EmergencyContacts: 'Emergency Contacts',
    PersonalInformation: 'Personal Information',
    UserCenter: 'User Center',
    pwdLogin: 'Password Login',
    smsLogin: 'Verification code Login',
    register: 'Register',
    login: 'Login',
  },
  table:{
    btnLogin:"Log in",
    week: 'Seven days a week',
    everyDay: 'Every day: 8am - 8pm',
    continue: 'Continue',
    login: 'Login',
    pin: 'PIN',
    cancel:'Cancel',
    confirm: 'Confirm',
    tips: 'Tips',
    validCode: 'Verification code',
    getCode: 'OTP',
    agree: 'I have Read and Agree',
    forgotPassword: 'Forgot password?',
    clickHere: 'Click here',
    BankName: 'Bank name',
    Branch: 'Branch',
    CardHolderName: "CardHolder\'s name",
    BankAccount: 'BankAccount',
    add: 'Add',
    Details: 'Details',
    submit: 'Submit',
    next: 'Next',
    copy:'Copy',
    passwordConfirmation: 'password confirmation',
    upload: 'Upload',
    more: 'MORE >>',
    vipBenefits: 'VIP User Benefits',
    TakeLoan:'Take a loan',
    step: 'Step',
    phone: '0729171546',
    email:'business@cashavatar.online',
    facebook:'https://www.facebook.com/CashAvatar.live',
    desktop:'Add the CashAvatar site shortcut to your desktop'
  },
  tips:{
    phoneTips: 'Phone number cannot be empty',
    passwordTips:'Enter 6 password numbers',
    loginOut:'Are you sure you want to exit?',
    exit:'exit successfully',
    codeTips: 'Enter Verification code',
    PleaseEnter: 'Please Enter',
    PleaseSelect: 'Please Select',
    delectTips: 'Are you sure to delete it?',
    voucheruploadTips: 'Please upload the repayment voucher',
    copyTips: 'Copied successfully',
    agreeTips:'Please accept the Privacy Plolicy,Terms&Conditions and Loan Agreement',
    disableTip:'Your level is too low to request a product change',
 },
  step: {
    pageTitle1: 'Personal Information',
    pageTitle2: 'Emergency Contacts',
    pageTitle3: 'Bank Information',
    pageTitle4: 'Identity Information',
    firstName: 'First name',
    firstNameError: 'Please enter First name',
    lastName: 'Last name',
    lastNameError: 'Please enter Last name',
    gender: 'Gender',
    genderError: 'Please select Gender',
    nic: 'NIC',
    nicError: 'Please enter NIC',
    email: 'Email',
    emailError: 'Please enter Email',
    birthday: 'Birthday',
    birthdayError: 'Please select Birthday',
    eduction: 'Education',
    eductionError: 'Please select Education',
    address: 'Full address',
    addressError: 'Please enter address',
    safeTips: 'We will keep your information safe',
    companyName: 'Company Name',
    companyNameError: 'Please enter Company Name',
    monthlyIncome: 'Monthly income',
    monthlyIncomeError: 'Please select Monthly income',
    job: 'Position',
    jobError: 'Please select Position',
    companyInformation: 'Company Information',
    name: 'Name',
    nameError: 'Please enter Name',
    phone: 'Phone',
    phoneError: 'Please enter Phone',
    relationship: 'Relationship',
    relationshipError: 'Please select Relationship',
    emergencyContacts: 'Emergency contacts',
    titleTips: 'The emergency contact cannot be the same person; If they are the same then the application will fail.',
    bankName: 'Bank Name',
    bankNameError: 'Please select your Account Opening Bank',
    branch: 'Branch',
    branchError: 'Please select Branch',
    CardHolderName: "CardHolder\'s name",
    CardHolderNameError: "Please enter CardHolder\'s name",
    bankAccount: 'Bank Account',
    bankAccountError: 'Please enter Account',
    nicFront:'NIC Front',
    nicBack:'NIC Back',
    selfiePhoto:'Selfie Photo',
    revenuePhotos:'Photo with Proof of income',
    nicFrontError:'Please upload the photo of NIC Front',
    nicBackError:'Please upload the photo of NIC Back',
    selfiePhotoError:'Please upload the photo with NIC held in hand',
    incomePhotoError:'Please upload the photo of income certificate',
    photoTips:'Please try to take clear pictures, otherwise it will affect the recognition results.',
    bankTips:'It is recommended to use Sampath Bank PLC, you will receive loan funds quickly, there will be a 48-hour delay when using other banks',
    workLicense: 'Work License',
    workLicenseError: 'Please upload the photo of Work License',
    cameraTips:'It\'s difficult to change this to take pictures. Please click for help.',
  },
  loan:{
    loanTitle:'Apply earlier,get loan Faster!',
    LoanAmount:'Loan Amount',
    loan2:'Loan term and details',
    loan3:'include vat',
    loan4:'We are evaluating your credit status, please be patient, we will be done soon. Please keep the network open',
    loanPeriod:'Loan period',
    days:'days',
    amountReceived:'Amount received',
    paymentAmount:'Payment Amount',
    paymentAccount: 'Payment Account',
    interest:'interest',
    origniationFee:'Origniation fee',
    bankAccount:'Bank Account',
    toBorrowMoney:'Apply for loan',
    goHome:'Go to Home',
    applyTips:'Your application will be reviewed within 2 hours',
    dueRepayment:'Repayment Amount',
    repaymentDate:'Repayment Date',
    duration:'Duration',
    orderNumber:'Order Number',
    repayBank:'Repay to Bank',
    submitButton:'Submit repayment voucher',
    offlineTitle:'Offline repayment account',
    label2:'Branch',
    label3:'Repayment bank',
    remark:"remark",
    remarkContent:'If there is no remark order number, please add your transfer remark, otherwise we will not be able to verify the bill.',
    cameraTips:'If repayment slip cannot be uploaded. Please click for help',
    Voucher: 'Upload Repayment Voucher'
  },
  index:{
    title:'Get an online loan',
    title2:'in just',
    title3:'10 minutes!',
    title4:'Settlement amount',
    title5:'CashAvatar advantages',
    title6:'Fast money',
    title7:'We transfer money instantly to your account',
    title8:'Simplest process',
    title9:'Easy and short registration with valid NIC number',
    title10:'Easy repayment',
    title11:'Convenient repayment through your bank branch or with internet bank service',
    title12:'Who can use our service?',
    title13:'Age',
    title14:'20-60 years old',
    title15:'Location',
    title16:'Resident on Sri Lanka',
    title17:'Occupation',
    title18:'Have an income',
    title19:'Easy and fast steps to get a loan',
    title20:'Choose the loan amount',
    title21:'Complete the form with personal data',
    title22:'Wait for review results',
    title23:'Sign the contract and receive money',
  },
  HowToBorrow:{
    title:'How to get a loan in CashAvatar?',
    title2:'Here is a step-by-step guide to help you through the process',
    title3:'Determine your loan amount',
    title4:'As a new customer, simply select the desired loan amount',
    title5:'Fill out the form',
    title6:"All you need is your ID and phone number. It’s quick and easy – just a few minutes to complete",
    title7:'Await an application decision',
    title8:"We’ll notify you immediately or reach out if we need more information",
    title9:'Sign the agreement',
    title10:"Once you confirm your agreement to obtain the loan online, you’ll receive a confirmation code via SMS. And with our streamlined process, your loan will be transferred in real-time, making it as simple as can be",
  },
  HowToRepay:{
    title:'How to repay',
    title2:"At CashAvatar, we recognize that each customer has unique needs and preferences. That’s why our repayment options are designed to give you the flexibility to choose how you want to pay back your loan",
    title3:"To maximize convenience, you can choose from a variety of payment methods:",
    title4:'Money transfer within 1-3 days',
    title5:'Sampath Bank transfer',
    title6:"You can easily pay back via Sampath Bank branches choosing one of the closest to your location.",
    title7:'Please fill in the Sampath Bank deposit slip with the following information and hand it over to the teller:',
    title8:'Account holder Name',
    title9:'Megafin Lanka (pvt) Ltd',
    title10:'Account Number',
    title11:'0214 1000 2700',
    title12:'Branch',
    title13:'Orugodawatta',
    title14:"In order to make a payment through the bank’s CDM Sampath bank, you must:",
    title15:'Please select the language;',
    title16:'Select',
    title17:'CASH DEPOSIT',
    title18:'Enter your 10-digit mobile number;',
    title19:'Enter your',
    title20:'NIC number',
    title21: 'Enter Sampath Bank account number as',
    title22: 'Make sure the account name is automatically displayed as',
    title23: 'Order NO',
    title24: 'again as',
    title25: 'REFERENCE NO',
    title26: "Place cash notes into the deposit tray (Only Rs. 100 and higher cash notes can be used);",
    title27: 'The amount you deposited will be displayed on the screen. Press “Proceed” if correct;',
    title28: 'Take the payment receipt from the machine and keep it.',
    title29: 'NOTE:',
    title30: "Whenever you make a repayment, send us a clear slip photo or screenshot of your Payment Slip on to our phone numbers 94712015180 via WhatsApp, Viber, imo or our Facebook Messenger with your Order NO.",
  },
  about:{
    title:'About us',
    title2:"CashAvatar understand that getting the money you need for your plans can be a hassle, which is why we provide fast, easy, and flexible financing solutions through our online platform. Whether you’re looking to make small changes or take on big projects, our service is designed to help you focus on your goals without worrying about tedious or complicated procedures. Aspiring to become the leading provider of online money services in Sri Lanka, we pride ourselves on understanding the needs and aspirations of our customers, and we’re committed to providing you with the best possible experience. CashAvatar is here to help you get the funding you need, quickly and securely",
    title3:'Why CashAvatar?',
    title4:'Clear terms and loan amounts',
    title5:'Simplest process and easy repayment',
    title6:'Lowest fees in Sri Lanka',
    title7:'96% approved application',
    title8:'Customer loyalty program',
    title9:'The service is fully automated',
  },
  contact:{
    title:'Contact us',
    title2:'Support service',
    title3:'Mail address',
    title4:'Schedule',
    title5:'Facebook page',
    title6:'Address',
    title7:'No. 47 Alexandra Place, Colombo 07',
  },
  faq:{
    title:'FAQ',
    title2:'What is CashAvatar?',
    title3:"CashAvatar is an online lending platform for those facing unexpected expenses and needing a loan quickly. The loan amount can range from Rs 5,000 to Rs 80,000.",
    title4:'Credit information',
    title5:"Here’s what you’ll need regarding your credit information:",
    title6:'Age and Employment: to be eligible for a loan, you must be between the ages of 20 to 60 and currently employed or have a steady income.',
    title7:'Personal and Bank Information: make sure to accurately input your personal and bank account information.',
    title8:"Telephone and work information: the telephone number you use for the application must be registered in your name. Additionally, you’ll need to provide a landline number for your place of work.",
    title9:"Identification verification: to verify your identity, you’ll need to include a photo of your National Identity Card.",
    title10:"By providing all of this information, you’ll increase your chances of being approved for a loan. Remember to double-check your information to ensure accuracy and speed up the process.",
    title11:'How much is the service and consulting fees?',
    title12:"You can easily check the full repayment amount before you apply through our loan calculation form. As long as you make timely payments, there shouldn’t be any additional fees. Be sure to check the repayment amount before accepting the loan and only take it if you’re confident you can pay it back on time. Our priority is to provide you with flexible and affordable loan options.",
    title13:"How do I know if the loan is approved?",
    title14:"Curious about the status of your loan application? Rest easy knowing you can track it through your private cabinet on our website. Plus, once your loan is approved, we’ll send you an exciting notification via SMS! Stay in the loop and let CashAvatar help you reach your financial goals.",
    title15:'How long will it take to process my loan application?',
    title16:"As soon as you submit your loan application, we’ll keep you in the loop every step of the way. We’ll notify you right away about your application status or contact you if we need any additional information. And don’t worry about lingering in limbo – our application processing time typically ranges from just 10 minutes to one business day during our working hours. We’ll work quickly to get you the answers you need so you can proceed with confidence.",
    title17:'I did not receive a loan approval notification from CashAvatar. What can I do?',
    title18:"If you haven’t received a loan approval notification from CashAvatar, don’t worry! We’re here to assist you. Our friendly team can help you with any queries or concerns you may have. Reach out to us through any of our communication channels:",
    title19:"Facebook",
    title20:"Email",
    title21:"Phone",
    title22:"About cash receipts",
    title23:"When it comes to cash receipts and loan applications, we want to ensure that you have access to your funds as soon as possible. That’s why we strive to have your money credited to your bank account within just two hours of approving your loan application. You can trust us to deliver fast and reliable service to help you get the financial support you need.",
    title24:"About making payments to us",
    title25:"As part of your loan contract, we have specified the date and amount for repayment. To avoid any penalties, it’s important that you make your payment on or before the due date. Don’t hesitate to contact us if you have any questions or concerns about your payments.",
    title26:"Can I repay the loan earlier?",
    title27:"Absolutely! We understand that sometimes unexpected financial situations arise, and you may want to pay off your loan earlier than originally planned. You are free to make early payments at any time with no penalty fees. Just be sure to make the payments before the due date to avoid any late fees. We want to make your loan experience as flexible and easy as possible, so don’t hesitate to reach out to us with any questions or concerns.",
  }
}
